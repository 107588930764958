import { render, staticRenderFns } from "./AboutAndEmployment.vue?vue&type=template&id=67b42f1e&"
import script from "./AboutAndEmployment.vue?vue&type=script&lang=js&"
export * from "./AboutAndEmployment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TitleRow: require('/codebuild/output/src3113914139/src/eddys/components/TitleRow.vue').default,SubTitleRow: require('/codebuild/output/src3113914139/src/eddys/components/SubTitleRow.vue').default,ResponsivePicture: require('/codebuild/output/src3113914139/src/eddys/components/global/ResponsivePicture.vue').default,ComponentBlockOptions: require('/codebuild/output/src3113914139/src/eddys/components/component/block/Options.vue').default,SectionLayoutWrapper: require('/codebuild/output/src3113914139/src/eddys/components/global/SectionLayoutWrapper.vue').default})
